import ADG_API from './base';

const GET_BANKS_URL = "/international-payment/tracking/bank";

const getBanks = async (dispatch = () => { }) => {
    return ADG_API.get(GET_BANKS_URL).catch((errRes) => {
        ADG_API.mapError(dispatch,errRes.response);
        throw errRes;
    });
}

const BankService = {
    getBanks,
}

export default BankService;