import ADG_API from './base';

const GET_TRANSACTION_OF_ORDER = "/international-payment/tracking/bank/${1}/transaction";
const UPDATE_NOTE_HISTORY = "/international-payment/tracking/transaction/update-note";
const CANCEL_TRANSACTION_HISTORY = "/international-payment/tracking/transaction/${1}";
const PARTIAL_EXPORT_URL = "/international-payment/tracking/transaction/partial-export/";
const EXPORT_DATA_URL = "/international-payment/tracking/bank/${1}/transaction/export";

const getTransactionOfOrder = async (bankId, data, dispatch = () => { }) => {
    return ADG_API.post(GET_TRANSACTION_OF_ORDER.replace("${1}", bankId), data).catch((errRes) => {
        ADG_API.mapError(dispatch, errRes.response);
        throw errRes;
    });
}
const updateNote = async (data, dispatch = () => { }) => {
    return ADG_API.put(UPDATE_NOTE_HISTORY, data).catch((errRes) => {
        ADG_API.mapError(dispatch, errRes.response);
        throw errRes;
    });
}
const cancelStatus = async (orderId, dispatch = () => { }) => {
    return ADG_API.delete(CANCEL_TRANSACTION_HISTORY.replace("${1}", orderId)).catch((errRes) => {
        ADG_API.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const exportData = async (bankId, data, dispatch = () => { }) => {
    return ADG_API.post(EXPORT_DATA_URL.replace("${1}", bankId), data, {
        responseType: "blob",
    }).catch((errRes) => {
        ADG_API.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const partialExport = async (data, dispatch = () => { }) => {
    return ADG_API.put(PARTIAL_EXPORT_URL, data).catch((errRes) => {
        if (errRes.response?.status === 400) {
            throw errRes;
        } else {
            ADG_API.mapError(dispatch, errRes.response);
        }
    });
}

const OrderTransactionService = {
    getTransactionOfOrder,
    updateNote,
    cancelStatus,
    exportData,
    partialExport
}

export default OrderTransactionService;