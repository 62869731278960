import history from "@history";
import { MESSAGE_STATUS, showMessageWithStatus } from "app/store/adg/messageSlice";
import axios from "axios";

const ADG_API = axios.create({
    baseURL: "https://api.adg-internal.com",
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    },
});

ADG_API.mapError = (dispatch, errRes) => {
    if (errRes.status == 404) {
        dispatch(showMessageWithStatus("Lỗi không tìm thấy (404)", MESSAGE_STATUS.error));
    } else if (errRes.status == 400) {
        dispatch(showMessageWithStatus("Lỗi thông tin không chính xác (400)", MESSAGE_STATUS.error));
    } else if (errRes.status >= 500) {
        history.push("/error/500");
    } else {
        dispatch(showMessageWithStatus("Lỗi " + errRes.status, MESSAGE_STATUS.error));
    }
}

export default ADG_API;

